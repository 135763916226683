import styled from 'styled-components'
import { Row, Column, Flex, Button } from '@renderbus/common/components'
import { Media, typography, spacing } from '@renderbus/common/theme'
import Decoration from '../images/2020-1111/decoration.png'

export const PromotionList = styled(Row)`
  padding: 100px 50px;
  ${Media.lessThan(Media.small)} {
    padding: 30px 0;
  }
`
export const ActivityBox = styled(Column)`
  img {
    width: 100%;
  }
  p {
    color: white;
  }
`
export const BannerContainer = styled(Flex)`
  position: relative;
  height: 600px;
`
export const SubTitle = styled.p`
  background-image: linear-gradient(to right, #f3dd38, #e2f95a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: ${typography.title};
  position: relative;
  margin: ${spacing.large} auto 0 auto;
  :before {
    content: '';
    background: url(${Decoration});
    background-size: 40px 40px;
    position: absolute;
    height: 40px;
    width: 40px;
    left: -50px;
    top: ${spacing.small};
    ${Media.lessThan(Media.small)} {
      background-size: ${spacing.large};
      height: ${spacing.large};
      width: ${spacing.large};
      left: -40px;
      top: 0;
    }
  }
  :after {
    content: '';
    background: url(${Decoration});
    background-size: 40px 40px;
    position: absolute;
    height: 40px;
    width: 40px;
    right: -50px;
    top: 10px;
    ${Media.lessThan(Media.small)} {
      background-size: ${spacing.large};
      height: ${spacing.large};
      width: ${spacing.large};
      right: -40px;
      top: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 160px;
    font-size: ${typography.h3};
  }
`
export const ActivityTime = styled.p`
  background-image: linear-gradient(to right, #18ecff, #23ff98);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: ${typography.h3};
  margin-top: ${spacing.large};
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h3};
  }
`
export const Double11Img = styled.img`
  ${Media.lessThan(Media.small)} {
    width: 95%;
  }
`
export const Double11Button = styled(Button)`
  width: 200px;
  height: 50px;
  line-height: 50px;
  border-radius: 1.5rem;
  text-align: center;
  font-size: 20px;
  margin-top: ${spacing.small};
  ${Media.lessThan(Media.small)} {
    width: 160px;
    height: 40px;
    line-height: 40px;
    font-size: ${typography.h3};
  }
`
export const Container = styled.div`
  min-width: 1400px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  ${Media.lessThan(Media.small)} {
    width: 100%;
  }
`
